
import { Vue, Component, Prop } from 'vue-property-decorator';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';
import WorkplaceEdit from '@/components/Workplace/WorkplaceEdit.vue';

@Component({
  components: {
    WorkplaceEdit
  }
})
export default class WorkplaceInfo extends Vue {
  edit:boolean = false;
  workplaceInfoEdit!:UserWorkplaceInfoClass;
  @Prop()workplaceInfo!:UserWorkplaceInfoClass;

  update():void{
    this.workplaceInfoEdit = this.workplaceInfo;
    this.edit = true;
  }
  copy():void{
    this.workplaceInfoEdit = UserWorkplaceInfoClass.copy(this.workplaceInfo);
    this.$emit('copy', this.workplaceInfoEdit);
  }
  cancel():void{
    this.edit = false;
  }
  deleteWorkplace():void{
    this.$emit('del', this.workplaceInfo.workplaceId);
  }
  reload():void{
    this.$emit('reload');
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';

@Component({
  components: {
  }
})
export default class UserInfo extends Vue {
  @Prop()workplaceInfo!:UserWorkplaceInfoClass;
}

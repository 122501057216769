
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import WorkplaceApi from '@/module/api/WorkplaceApi';
import { ErrorResponseModel } from '@/model/StaffManager';

@Component({
  components: {
    InputJpDate
  }
})
export default class WorkplaceEdit extends Vue {
  openEdit:boolean = true;
  @Prop({ default: false }) edit ?:boolean;
  @Prop()workplaceInfo!:UserWorkplaceInfoClass;

  cancel():void{
    this.openEdit = false;
  }

  cancelEdit():void{
    this.$emit('cancel');
  }

  editsave():void{
    WorkplaceApi.update(this.workplaceInfo).then(() => {
      this.$emit('cancel');
      this.$emit('reload');
      this.completeSave();
    }).catch((error:AxiosError<ErrorResponseModel>) => {
      if (error.response?.data.code === 'DuplicateKey') {
        this.$root.$emit('event-show-snackbar-infinity', error.response?.data.detail);
        return;
      }
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました');
    });
  }
  save():void{
    WorkplaceApi.save(this.workplaceInfo).then(() => {
      this.openEdit = false;
      this.$emit('reload');
    }).catch((error:AxiosError<ErrorResponseModel>) => {
      if (error.response?.data.code === 'DuplicateKey') {
        this.$root.$emit('event-show-snackbar-infinity', error.response?.data.detail);
        return;
      }
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました');
    });
  }
  isValid(): boolean {
    if (!this.workplaceInfo.startDate.isValid) {
      return false;
    }
    if (!this.workplaceInfo.endDate.isValid) {
      return false;
    }
    return true;
  }
  completeSave():void{
    this.$root.$emit('event-show-snackbar', '登録完了しました');
  }
}

import TypeJpDate from '@/model/type/TypeJpDate';
import UserID from '../UserID';
import UserName from '../UserName';
import Company from './Company';
import PrimeContractor from './PrimeContractor';
import Workplace from './Workplace';
import WorkplaceID from './WorkplaceID';

export default class UserWorkplaceInfoClass {
  workplaceId: WorkplaceID;

  /** ユーザーID */
  userId: UserID;

  userName: UserName;

  company: Company;

  primeContractor: PrimeContractor;

  workplace: Workplace;

  startDate: TypeJpDate;

  endDate: TypeJpDate;

  constructor(
    workplaceId?:WorkplaceID,

    userId?:UserID,

    userName?: UserName,

    company?: Company,

    primeContractor?: PrimeContractor,

    workplace?: Workplace,

    startDate?: TypeJpDate,

    endDate?: TypeJpDate,
  ) {
    this.workplaceId = (workplaceId === undefined ? new WorkplaceID() : workplaceId);
    this.userId = (userId === undefined ? new UserID() : userId);
    this.userName = (userName === undefined ? new UserName() : userName);
    this.company = (company === undefined ? new Company() : company);
    this.primeContractor = (primeContractor === undefined ? new PrimeContractor() : primeContractor);
    this.workplace = (workplace === undefined ? new Workplace() : workplace);
    this.startDate = (startDate === undefined ? TypeJpDate.getToday() : startDate);
    this.endDate = (endDate === undefined ? TypeJpDate.getToday() : endDate);
  }

  public static copy(origin:UserWorkplaceInfoClass):UserWorkplaceInfoClass {
    return new UserWorkplaceInfoClass(
      new WorkplaceID(),
      origin.userId,
      origin.userName,
      origin.company,
      origin.primeContractor,
      origin.workplace,
      TypeJpDate.getToday(),
      TypeJpDate.getToday()
    );
  }
  public static newsave(origin:UserWorkplaceInfoClass):UserWorkplaceInfoClass {
    return new UserWorkplaceInfoClass(
      new WorkplaceID(),
      origin.userId,
      origin.userName,
      new Company(),
      new PrimeContractor(),
      new Workplace(),
      TypeJpDate.getToday(),
      TypeJpDate.getToday()
    );
  }

  public static create(): UserWorkplaceInfoClass[] {
    const result:UserWorkplaceInfoClass[] = [];
    return result;
  }
}

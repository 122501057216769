
import { Vue, Component } from 'vue-property-decorator';
import UserInfo from '@/components/Workplace/UserInfo.vue';
import WorkplaceInfo from '@/components/Workplace/WorkplaceInfo.vue';
import WorkplaceEdit from '@/components/Workplace/WorkplaceEdit.vue';
import WorkplaceApi from '@/module/api/WorkplaceApi';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';
import WorkplaceID from '@/model/user/Workplace/WorkplaceID';
import BackButton from '@/components/Parts/BackButton.vue';
import UserID from '@/model/user/UserID';

@Component({
  components: {
    UserInfo,
    WorkplaceInfo,
    WorkplaceEdit,
    BackButton
  }
})
export default class WorkplaceList extends Vue {
  cnt = 0;
  workplaceList:UserWorkplaceInfoClass[]=[];
  appendedWorkplace:UserWorkplaceInfoClass[]=[];
  userId!:UserID;
  workplaceInfo:UserWorkplaceInfoClass = new UserWorkplaceInfoClass();
  mounted():void{
    WorkplaceApi.findById(this.$route.params.userId).then((res) => {
      this.workplaceList = res.data;
    });
  }
  add(addWorkplace:UserWorkplaceInfoClass):void{
    this.workplaceList.push(addWorkplace);
  }
  workplaceAdd():void{
    if (this.workplaceList[0] === undefined) {
      this.appendedWorkplace.push(new UserWorkplaceInfoClass());
      this.appendedWorkplace[0].userId.value = this.$route.params.userId;
    }
    this.appendedWorkplace.push(UserWorkplaceInfoClass.newsave(this.workplaceList[0]));
  }
  copy(copyWorkplace:UserWorkplaceInfoClass):void{
    this.appendedWorkplace.push(copyWorkplace);
  }
  del(workplaceId:WorkplaceID):void{
    WorkplaceApi.delete(workplaceId.value).then(() => {
      this.reload();
      this.$root.$emit('event-show-snackbar', '削除されました。');
    });
  }
  reload():void{
    WorkplaceApi.findById(this.$route.params.userId).then((res) => {
      this.workplaceList = res.data;
    });
  }
}
